import LoadingBlock from "../LoadingBlock";
import styles from '../styles/SchoolFeedBlock.module.scss';
import ErrorBlock from "../ErrorBlock";
import SchoolFeedList from "../../../schoolfeed/SchoolFeedList";
import {useContext, useEffect} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {observer} from "mobx-react";
import {INewsBlockProps} from "./NewsBlock";
import ViewMoreButton from "../../TerracedGridTheme/ViewMoreButton";
import {useFirstPageSchoolFeedContent} from "../../../schoolfeed/hooks/useFirstPageSchoolFeedContent";
import {ReactiveContentItem} from "../../../../stores/SchoolFeedStore";

const SchoolFeedBlock = observer((props: INewsBlockProps) => {
    const {organizationStore} = useContext(StoreContext);

    const {content, isLoading, fetchStatus, isMoreContent, setContent} = useFirstPageSchoolFeedContent({
        organization_id: organizationStore.currentOrganization.id,
    })

    useEffect(() => {
        props.blockObj.setAttributes({asyncLoaded: !props.blockObj.asyncLoaded});
    },[content])

    if (isLoading && content.length === 0) { // also use data.length because when fetching subsequent pages we don't want to show loading block
        return <LoadingBlock/>
    } else if (fetchStatus === "FAILURE") {
        return <ErrorBlock blockObj={{errorMessage: 'Oops! There was a problem fetching this feed.'}}/>
    }

    function handleDelete(contentItem) {
        setContent(v => v.filter(n => n.id !== contentItem.id));
    }

    return <div className={styles.container}>
        <SchoolFeedList
            showMedia={props.blockObj.sizeX > 1}
            allowCuration={true}
            content={content}
            handleDelete={handleDelete}
            totalItemCount={content.length}
            getItemAtIndex={idx => {
                return content[idx] as ReactiveContentItem || null;
            }}
        />
        {isMoreContent && <div style={{position:"relative"}}>
            <ViewMoreButton organizationId={organizationStore.currentOrganization.id} text={"View More News"} />
        </div>}
    </div>;
})

export default SchoolFeedBlock;
