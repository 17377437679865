import {useContext, useState} from "react";
import {
    IReactiveEventContentItem,
    IReactiveNewsContentItem,
    ReactiveContentItem,
    SchoolFeedContentItem
} from "../../../stores/SchoolFeedStore";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import WatsonApi from "../../../backends/WatsonApi";
import moment from "moment";
import {StoreContext} from "../../../stores/StoreLoader";
import {isEvent} from "../ListItemGroup";
import {useMachine} from "@xstate/react/lib";
import {FetchMachine} from "../../machines/FetchMachine";

function isNotEmpty(item) {
    return item.title || item.image || item.description;
}

function getOrganizedContent(allContent: Array<EventContentItem | NewsContentItem | FileContentItem | MediaContentItem>): Array<SchoolFeedContentItem> {
    const c: Array<SchoolFeedContentItem> = [];
    let currentNewsIndex = 0;
    let currentEventsIndex = 0;

    const mixedNews: Array<NewsContentItem | FileContentItem | MediaContentItem> = [];
    const events: EventContentItem[] = [];

    function shouldAddEvent(eventItem: EventContentItem): boolean {
        return isNotEmpty(eventItem) && !events.find(n => {
            return n.source_id !== eventItem.source_id &&
                n.json_data.event.start === eventItem.json_data.event.start &&
                n.json_data.event.end === eventItem.json_data.event.end &&
                n.title === eventItem.title &&
                n.description === eventItem.description
        })
    }

    function shouldAddNews(newsItem: NewsContentItem | FileContentItem | MediaContentItem): boolean {
        return isNotEmpty(newsItem) && !mixedNews.find(n => {
            return n.source_id !== newsItem.source_id &&
                n.title === newsItem.title &&
                n.description === newsItem.description
        })
    }

    allContent.forEach(c => {
        if (isEvent(c)) {
            events.push(c);
        } else {
            mixedNews.push(c);
        }
    })

    for (let i = 0; i < mixedNews.length + events.length; i++) {
        if ((i % 3 === 0 && currentEventsIndex < events.length) || currentNewsIndex === mixedNews.length) {
            if (shouldAddEvent(events[currentEventsIndex])) {
                const r: unknown = new ReactiveContentItem(events[currentEventsIndex]);
                c.push(r as IReactiveEventContentItem);
            }
            currentEventsIndex++;
        } else if (currentNewsIndex < mixedNews.length) {
            if (shouldAddNews(mixedNews[currentNewsIndex])) {
                const r: unknown = new ReactiveContentItem(mixedNews[currentNewsIndex]);
                c.push(r as IReactiveNewsContentItem);
            }
            currentNewsIndex++;
        }
    }
    return c;
}

export function useFirstPageSchoolFeedContent(
    baseFilterParams: { [k: string]: any }, // valid content item filtering parameters,
    fetchNow: boolean = true // used to delay fetch (i.e. until SchoolFeedWidget is opened)
) {
    const {userStore} = useContext(StoreContext);
    const [content, setContent] = useState<SchoolFeedContentItem[]>([]);
    const [isMoreContent, setIsMoreContent] = useState(false);
    const [current, send] = useMachine(FetchMachine);

    useAsyncEffect(async () => {
        if (!fetchNow) return;

        send("FETCH");
        const q = {
            ...baseFilterParams,
            expand: "organization",
            page: 1,
        }
        if (!userStore.editor) {
            q["published"] = true;
        }
        try {
            const client = await WatsonApi();
            const queries = [
                await client.apis["content-items"].content_items_list(Object.assign({
                    page_size: 10,
                    is_news: true,
                    is_media: true,
                    is_file: true,
                    is_coursework: true,
                }, q)),
                await client.apis["content-items"].content_items_list(Object.assign({
                    is_event: true,
                    page_size: 5,
                    event_end: moment().format('YYYY-MM-DD'),
                    ordering: "json_data__event__start",
                }, q)),
            ]
            const response = await Promise.all(queries);
            const newsResponse = JSON.parse(response[0].data);
            const eventsResponse = JSON.parse(response[1].data);
            const organizedResponse = [
                ...newsResponse.data,
                ...eventsResponse.data,
            ]
            setIsMoreContent(!!newsResponse.links.next || !!eventsResponse.links.next);
            setContent(getOrganizedContent(organizedResponse));
            send("FULFILL");
        } catch (e) {
            send("REJECT")
        }
    }, [fetchNow]);

    return {
        content,
        isMoreContent,
        setContent,
        isLoading: current.value === "PENDING",
        fetchStatus: current.value,
    };
}
